html {
  scroll-behavior: smooth;
}
* {
  scroll-padding: 80px;
}

.App {
  text-align: center;
}
